<script>
    import ItemTransactions from './ItemTransactions.svelte';
    import { setContext } from 'svelte';
    import * as Table from '$lib/components/ui/table';
    import WalletNav from './WalletNav.svelte';

    import * as Tabs from '$lib/components/ui/tabs';

    export let payments;
    export let PENDING_STATUS;
    export let REQUESTED_STATUS;
    export let PAYPAL_PROVIDER;
    export let STRIPE_PROVIDER;
    export let MANUAL_PROVIDER;
    export let COMMAND_PROVIDER;
    export let CREDIT_PROVIDER;
    export let COINBASE_PROVIDER;
    export let CCBILL_PROVIDER;
    export let NOWPAYMENTS_PROVIDER;
    export let PAYSTACK_PROVIDER;
    export let APPROVED_STATUS;
    export let REJECTED_STATUS;
    export let authUser;
    export let getSiteDirection;

    export let referral_transactions;
    export let post_transactions;

    export let errors = [];

    $: {
        const walletTransactionContext = {
            referral_transactions,
            post_transactions,
        };
        setContext('walletTransactionContext', walletTransactionContext);
    }
</script>

<div class="pr-0">
    <div class="p-4">
        <svelte:component this="{WalletNav}" />
        <div class="mt-3">
            <Tabs.Root value="all" class="w-full">
                <Tabs.List>
                    <Tabs.Trigger value="all">All</Tabs.Trigger>
                    <Tabs.Trigger value="items">Items</Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value="all">
                    {#if payments.length > 0}
                        <Table.Root>
                            <Table.Caption>Your payment history</Table.Caption>
                            <Table.Header>
                                <Table.Row>
                                    <Table.Head>Type</Table.Head>
                                    <Table.Head>Status</Table.Head>
                                    <Table.Head>Amount</Table.Head>
                                    <Table.Head class="hidden md:table-cell">From</Table.Head>
                                    <Table.Head class="hidden md:table-cell">To</Table.Head>
                                    <Table.Head class="hidden md:table-cell">Reference</Table.Head>
                                    <Table.Head></Table.Head>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {#each payments as payment}
                                    <Table.Row>
                                        <Table.Cell>
                                            {#if payment.type == 'stream-access'}
                                                {#if payment.stream.status == 'in-progress'}
                                                    <a
                                                        href="/stream/{payment.stream.id}/{payment.stream.slug}"
                                                        class="text-dark"
                                                    >
                                                        {payment.type}
                                                    </a>
                                                {:else if payment.stream.settings['dvr'] && payment.stream.vod_link}
                                                    <a
                                                        href="/stream/archive/{payment.stream.id}/{payment.stream.slug}"
                                                        class="text-dark"
                                                    >
                                                        {payment.type}
                                                    </a>
                                                {:else}
                                                    <span title="Stream VOD unavailable">{payment.type}</span>
                                                {/if}
                                            {:else if payment.type == 'post-unlock'}
                                                <a
                                                    href="/posts/get/{payment.post.id}/{payment.receiver.username}"
                                                    class="text-dark"
                                                >
                                                    {payment.type}
                                                </a>
                                            {:else if payment.type == 'tip'}
                                                {payment.type}
                                                {#if payment.post_id}
                                                    <a
                                                        href="/posts/get/{payment.post.id}/{payment.receiver.username}"
                                                        class="text-dark">Post</a
                                                    >
                                                {:else if payment.stream_id}
                                                    {#if payment.stream.settings['dvr'] && payment.stream.vod_link}
                                                        <a
                                                            href="/stream/{payment.stream.id}/{payment.stream.slug}"
                                                            class="text-dark">Stream</a
                                                        >
                                                    {:else}
                                                        <span title="Stream VOD unavailable">Stream</span>
                                                    {/if}
                                                {:else}
                                                    User
                                                {/if}
                                            {:else}
                                                {payment.type ?? 'Withdrawal'}
                                            {/if}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {#if payment.status === 'approved'}
                                                <span
                                                    class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 dark:bg-green-500/10 dark:text-green-400 dark:ring-green-500/20"
                                                >
                                                    {payment.status}
                                                </span>
                                            {:else if payment.status === 'pending'}
                                                <span
                                                    class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 dark:bg-yellow-400/10 dark:text-yellow-500 dark:ring-yellow-400/20"
                                                >
                                                    {payment.status}
                                                </span>
                                            {:else if payment.status === 'refunded'}
                                                <span
                                                    class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 dark:bg-blue-400/10 dark:text-blue-400 dark:ring-blue-400/30"
                                                >
                                                    {payment.status}
                                                </span>
                                            {:else if payment.status === 'partially-paid'}
                                                <span
                                                    class="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 dark:bg-indigo-400/10 dark:text-indigo-400 dark:ring-indigo-400/30"
                                                >
                                                    {payment.status}
                                                </span>
                                            {:else if payment.status === 'rejected'}
                                                <span
                                                    class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 dark:bg-red-400/10 dark:text-red-400 dark:ring-red-400/20"
                                                >
                                                    {payment.status}
                                                </span>
                                            {:else}
                                                <span
                                                    class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:text-gray-400 dark:ring-gray-400/20"
                                                >
                                                    {payment.status}
                                                </span>
                                            {/if}
                                        </Table.Cell>
                                        <Table.Cell>{payment.amount}</Table.Cell>
                                        <Table.Cell class="hidden md:table-cell">
                                            <a href="/{authUser.username}" class="text-dark-r">
                                                {payment.sender.name ?? 'Auth::user()->name'}
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell class="hidden md:table-cell">
                                            <a href="/{payment.receiver.username}" class="text-dark-r">
                                                {payment.receiver.name ?? 'Auth::user()->name'}
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell class="hidden md:table-cell" title="Your tooltip text here">
                                            {#if ['pending', 'requested', 'rejected'].includes(payment.status)}
                                                <span>/</span>
                                            {:else}
                                                <span>
                                                    {payment.type[0]}
                                                    {#if payment.payment_provider == PAYPAL_PROVIDER}
                                                        {PAYPAL_PROVIDER}/{payment.paypal_transaction_id}
                                                    {:else if payment.payment_provider == STRIPE_PROVIDER}
                                                        {STRIPE_PROVIDER}/{payment.stripe_transaction_id}
                                                    {:else if payment.payment_provider == MANUAL_PROVIDER}
                                                        {payment.payment_provide}/{payment.id}
                                                    {:else if payment.payment_provider == COMMAND_PROVIDER}
                                                        manual/v2/{payment.id}
                                                    {:else if payment.payment_provider == CREDIT_PROVIDER}
                                                        bmn/{payment.id}
                                                    {:else if payment.payment_provider == COINBASE_PROVIDER}
                                                        {COINBASE_PROVIDER}/{payment.coinbase_charge_id}
                                                    {:else if payment.payment_provider == CCBILL_PROVIDER}
                                                        {CCBILL_PROVIDER}/{payment.ccbill_transaction_id}
                                                    {:else if payment.payment_provider == NOWPAYMENTS_PROVIDER}
                                                        {NOWPAYMENTS_PROVIDER}/{payment.nowpayments_payment_id}
                                                    {:else if payment.payment_provider == PAYSTACK_PROVIDER}
                                                        {PAYSTACK_PROVIDER}/{payment.paystack_payment_token}
                                                    {/if}
                                                </span>
                                            {/if}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {#if payment.invoice_id && payment.receiver.id !== authUser.id && payment.status === APPROVED_STATUS}
                                                <div
                                                    class="dropdown {getSiteDirection == 'rtl'
                                                        ? 'dropright'
                                                        : 'dropleft'}"
                                                >
                                                    <a
                                                        class="btn btn-sm text-dark-r text-hover btn-outline-light dropdown-toggle m-0 px-2 py-1"
                                                        data-toggle="dropdown"
                                                        href="#"
                                                        role="button"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <!-- Include your icon here -->
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a
                                                            class="dropdown-item d-flex align-items-center"
                                                            href="/invoices/{payment.invoice_id}"
                                                        >
                                                            View invoice
                                                        </a>
                                                    </div>
                                                </div>
                                            {/if}
                                        </Table.Cell>
                                    </Table.Row>
                                {/each}
                            </Table.Body>
                        </Table.Root>
                    {:else}
                        <p class="p-3">There are no payments on this account.</p>
                    {/if}
                </Tabs.Content>
                <Tabs.Content value="items">
                    <svelte:component this="{ItemTransactions}" />
                </Tabs.Content>
            </Tabs.Root>
        </div>
        <slot></slot>
    </div>
</div>
<!-- Use the errors prop somewhere in your component -->
{#if errors.length}
    <ul>
        {#each errors as error}
            <li>{error}</li>
        {/each}
    </ul>
{/if}
